<template>
  <div class="page">
    <div class="list">
      <div class="item" v-for="item in originData" :key="item.key" draggable @dragstart="handleDragStart($event, item)">
        <p class="value">{{ item.val }}</p>
      </div>
    </div>
    <div
      class="requied_content"
      @dragenter.prevent="handleDragEnter"
      @dragover.prevent="handleDragOver"
      @drop="handleDragRequired"
    >
      <transition-group name="list" tag="p">
        <div class="cur_item list-item" v-for="item in targetData" :key="item.key" draggable>
          <p class="value">{{ item.val }}</p>
        </div>
      </transition-group>
    </div>
    <div id="list-demo" class="demo">
      <button v-on:click="add">Add</button>
      <button v-on:click="remove">Remove</button>
      <transition-group name="list" tag="p">
        <span v-for="item in items" v-bind:key="item" class="list-item">
          {{ item }}
        </span>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      originData: [
        {
          key: 0,
          val: '姓名'
        },
        {
          key: 1,
          val: '姓名2'
        }
      ],
      targetData: [],
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10
    }
  },
  methods: {
    randomIndex: function() {
      return Math.floor(Math.random() * this.items.length)
    },
    add: function() {
      this.items.splice(2, 0, 'name')
      // this.items.splice(this.randomIndex(), 0, this.nextNum++)
    },
    remove: function() {
      this.items.splice(this.randomIndex(), 1)
    },
    handleDragStart(e, item) {
      console.log(e, item)
      console.log(e.target)
      const stringifyItem = JSON.stringify(item)
      e.dataTransfer.setData('getItem', stringifyItem)
    },
    handleDragRequired(e) {
      console.log(e)
      const parseItem = JSON.parse(e.dataTransfer.getData('getItem'))
      this.targetData.push(parseItem)
    },
    handleDragEnter(e) {
      console.log(e.target)
      e.target.style.background = 'green'
    },
    handleDragOver(e) {
      console.log(e.target, '当前位置')
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to
/* .list-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
.list {
  width: 400px;
  height: 400px;
  background: red;
  .item {
    width: 50px;
    height: 50px;
    background: yellow;
  }
}
.requied_content {
  width: 400px;
  height: 400px;
  background: blue;
  padding-top: 50px;
  .cur_item {
    width: 50px;
    height: 50px;
    background: yellow;
  }
}
</style>
